import React from 'react';
import { Mail, Phone, Clock, MapPin, Shield, FileText } from 'lucide-react';

export default function ContactInfo() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Contact Information</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Get in touch with our management company and key contacts for any inquiries or assistance.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-8">
          <div className="bg-gray-50 rounded-lg p-8">
            <h3 className="text-xl font-semibold mb-6">Property Management</h3>
            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <MapPin className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="font-medium">Keystone Pacific Property Management</p>
                  <p className="text-gray-600">240 Commerce, Suite 200</p>
                  <p className="text-gray-600">Irvine, CA 92602</p>
                </div>
              </div>
              
              <div className="flex items-start gap-3">
                <Phone className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="text-gray-600">Office: (949) 403-7405</p>
                  <p className="text-gray-600">Emergency After Hours: (949) 833-2600</p>
                </div>
              </div>
              
              <div className="flex items-center gap-3">
                <Clock className="w-5 h-5 text-indigo-600" />
                <p className="text-gray-600">Association Manager: Melanie Clay</p>
              </div>

              <div className="flex items-center gap-3">
                <Mail className="w-5 h-5 text-indigo-600" />
                <a href="mailto:mclay@keystonepacific.com" className="text-gray-600 hover:text-indigo-600">
                  mclay@keystonepacific.com
                </a>
              </div>
            </div>
          </div>
          
          <div className="bg-gray-50 rounded-lg p-8">
            <h3 className="text-xl font-semibold mb-6">Additional Contacts</h3>
            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <FileText className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="font-medium">Common Area Issues</p>
                  <p className="text-gray-600">Phone: (949) 833-2600</p>
                  <a href="mailto:reconnect@keystonepacific.com" className="text-gray-600 hover:text-indigo-600">
                    reconnect@keystonepacific.com
                  </a>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <FileText className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="font-medium">Billing Questions/Address Changes</p>
                  <p className="text-gray-600">Phone: (949) 833-2600</p>
                  <a href="mailto:customercare@keystonepacific.com" className="text-gray-600 hover:text-indigo-600">
                    customercare@keystonepacific.com
                  </a>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <FileText className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="font-medium">Architectural Desk</p>
                  <p className="text-gray-600">Phone: (949) 838-3239</p>
                  <a href="mailto:architectural@keystonepacific.com" className="text-gray-600 hover:text-indigo-600">
                    architectural@keystonepacific.com
                  </a>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <Shield className="w-5 h-5 text-indigo-600 mt-1" />
                <div>
                  <p className="font-medium">Insurance Broker</p>
                  <p className="text-gray-600">Armstrong/Robitaille/Reigle</p>
                  <p className="text-gray-600">1500 Quail St., Suite #100</p>
                  <p className="text-gray-600">Newport Beach, CA 92660</p>
                  <p className="text-gray-600">(949) 381-7700</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}