import React from 'react';

export default function Hero() {
  return (
    <div className="relative h-[600px]">
      <div className="absolute inset-0">
        <img
          src="https://i.imgur.com/FhsX0Fe.jpeg"
          alt="Beautiful lake view with trees"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-black/40" />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 h-full flex items-center">
        <div className="text-white max-w-2xl drop-shadow-lg">
          <h1 className="text-5xl font-bold mb-6 text-shadow-lg">Welcome to Our Neighborhood</h1>
          <p className="text-xl font-medium text-shadow-md">Where community thrives and neighbors become family. Stay connected with local events, updates, and everything that makes our neighborhood special.</p>
        </div>
      </div>
    </div>
  );
}