import React from 'react';
import { Users, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface BoardMember {
  id: string;
  name: string;
  position: string;
}

export default function BoardMembers() {
  const boardMembers: BoardMember[] = [
    { id: "fred-laurino", name: "Fred Laurino", position: "President" },
    { id: "patrick-bole", name: "Patrick Bole", position: "Vice-President" },
    { id: "richard-pineros", name: "Richard Pineros", position: "Treasurer" },
    { id: "andrew-davies", name: "Andrew Davies", position: "Secretary" },
    { id: "stephanie-sutherland", name: "Stephanie Sutherland", position: "Member-at-Large" }
  ];

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Board of Directors</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Meet the dedicated team serving our Sycamore Creek community.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {boardMembers.map((member) => (
            <Link 
              key={member.id}
              to={`/board-member/${member.id}`}
              className="bg-white rounded-lg shadow-md p-6 flex items-center gap-4 hover:shadow-lg transition-all group"
            >
              <div className="bg-indigo-100 rounded-full p-3">
                <Users className="w-6 h-6 text-indigo-600" />
              </div>
              <div className="flex-grow">
                <h3 className="font-semibold text-lg">{member.name}</h3>
                <p className="text-gray-600">{member.position}</p>
              </div>
              <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-indigo-600 transition-colors" />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}