import React, { useState, useCallback, useEffect } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isSameDay, startOfWeek, endOfWeek, isWithinInterval, parseISO } from 'date-fns';
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon, Clock, MapPin, CalendarDays, HelpCircle, ChevronDown, ChevronUp, Smartphone, Globe } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { getEventsForMonth, Event, toPST } from '../utils/events';
import { EventCategory } from '../utils/eventTypes';
import GoogleCalendarButton from '../components/GoogleCalendarButton';

const categoryColors = {
  [EventCategory.HOLIDAY]: {
    bg: 'bg-blue-100',
    text: 'text-blue-600',
    label: 'Holidays'
  },
  [EventCategory.ROUTINE]: {
    bg: 'bg-gray-100',
    text: 'text-gray-600',
    label: 'Maintenance'
  },
  [EventCategory.FEATURED]: {
    bg: 'bg-indigo-100',
    text: 'text-indigo-600',
    label: 'Community Events'
  },
  [EventCategory.MEETING]: {
    bg: 'bg-red-100',
    text: 'text-red-600',
    label: 'Board Meetings'
  }
};

interface FAQItem {
  question: string;
  answer: React.ReactNode;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const FAQSection = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const faqs: FAQItem[] = [
    {
      question: "How do I create a Google Account?",
      answer: (
        <div className="space-y-4">
          <div>
            <h4 className="font-medium mb-2">On Desktop:</h4>
            <ol className="list-decimal list-inside space-y-2">
              <li>Visit <a href="https://accounts.google.com/signup" target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-700">accounts.google.com/signup</a></li>
              <li>Click "Create account"</li>
              <li>Choose "For myself"</li>
              <li>Enter your name, desired email, and password</li>
              <li>Follow the prompts to verify your phone number</li>
              <li>Complete the account setup process</li>
            </ol>
          </div>
          <div>
            <h4 className="font-medium mb-2">On Mobile:</h4>
            <ol className="list-decimal list-inside space-y-2">
              <li>Download the Gmail app from your device's app store</li>
              <li>Open the app and tap "Add an email address"</li>
              <li>Tap "Google" and then "Create account"</li>
              <li>Follow the on-screen instructions</li>
              <li>Verify your phone number when prompted</li>
            </ol>
          </div>
        </div>
      )
    },
    {
      question: "How do I subscribe to the community calendar?",
      answer: (
        <div className="space-y-4">
          <p>Once you have a Google Account:</p>
          <ol className="list-decimal list-inside space-y-2">
            <li>Click the "Subscribe to Community Calendar" button above</li>
            <li>Sign in to your Google Account if prompted</li>
            <li>Click "Add Calendar" when asked</li>
            <li>The calendar will now appear in your Google Calendar</li>
          </ol>
          <p className="text-sm text-gray-600">
            Note: You'll receive automatic updates whenever new events are added to the community calendar.
          </p>
        </div>
      )
    }
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-8">
      <h3 className="text-xl font-semibold mb-6">Frequently Asked Questions</h3>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b last:border-b-0 pb-4 last:pb-0">
            <button
              onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
              className="w-full flex justify-between items-center text-left"
            >
              <span className="font-medium">{faq.question}</span>
              {expandedIndex === index ? (
                <ChevronUp className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-500" />
              )}
            </button>
            {expandedIndex === index && (
              <div className="mt-4 text-gray-600">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default function CalendarPage() {
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const [searchParams] = useSearchParams();
  const initialDate = searchParams.get('date') 
    ? parseISO(searchParams.get('date')!)
    : new Date();
  
  const [currentDate, setCurrentDate] = useState(toPST(initialDate));
  const [selectedDate, setSelectedDate] = useState(toPST(initialDate));

  const allEvents = getEventsForMonth(currentDate);

  const start = startOfWeek(startOfMonth(currentDate));
  const end = endOfWeek(endOfMonth(currentDate));
  const days = eachDayOfInterval({ start, end });

  const getEventsForDate = useCallback((date: Date) => {
    return allEvents.filter(event => {
      const eventStart = parseISO(event.startDate);
      const eventEnd = event.endDate ? parseISO(event.endDate) : eventStart;
      
      return isWithinInterval(date, { start: eventStart, end: eventEnd });
    });
  }, [allEvents]);

  const isFirstDayOfEvent = useCallback((date: Date, event: Event) => {
    return isSameDay(date, parseISO(event.startDate));
  }, []);

  const isLastDayOfEvent = useCallback((date: Date, event: Event) => {
    if (!event.endDate) return isSameDay(date, parseISO(event.startDate));
    return isSameDay(date, parseISO(event.endDate));
  }, []);

  const goToToday = useCallback(() => {
    const today = toPST(new Date());
    setCurrentDate(today);
    setSelectedDate(today);
  }, []);

  const previousMonth = useCallback(() => setCurrentDate(subMonths(currentDate, 1)), [currentDate]);
  const nextMonth = useCallback(() => setCurrentDate(addMonths(currentDate, 1)), [currentDate]);

  const EventDetails = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold mb-4">
        Events for {format(selectedDate, 'MMMM d, yyyy')}
      </h3>
      <div className="space-y-4">
        {getEventsForDate(selectedDate).length > 0 ? (
          getEventsForDate(selectedDate).map((event, idx) => (
            <div key={idx} className="border-b pb-4 last:border-b-0 last:pb-0">
              <div className="flex items-start gap-3">
                <div className={`rounded-full p-2 ${categoryColors[event.category].bg}`}>
                  <CalendarIcon className={`w-5 h-5 ${categoryColors[event.category].text}`} />
                </div>
                <div>
                  <h4 className="font-medium">{event.title}</h4>
                  {event.endDate && (
                    <p className="text-sm text-gray-600 mt-1">
                      {format(parseISO(event.startDate), 'MMM d')} - {format(parseISO(event.endDate), 'MMM d, yyyy')}
                    </p>
                  )}
                  {event.time && (
                    <div className="flex items-center gap-1 text-sm text-gray-600 mt-1">
                      <Clock className="w-4 h-4" />
                      <span>{event.time}</span>
                    </div>
                  )}
                  {event.location && (
                    <div className="flex items-center gap-1 text-sm text-gray-600 mt-1">
                      <MapPin className="w-4 h-4" />
                      <span>{event.location.address}</span>
                    </div>
                  )}
                  <p className="text-sm text-gray-600 mt-2">
                    {event.description}
                  </p>
                  <div className="mt-4">
                    <GoogleCalendarButton event={event} />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center py-4">
            No events scheduled for this date
          </p>
        )}
      </div>
    </div>
  );

  const Calendar = () => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">
          {format(currentDate, 'MMMM yyyy')}
        </h2>
        <div className="flex gap-2">
          <button
            onClick={goToToday}
            className="flex items-center gap-1 px-3 py-2 bg-indigo-100 text-indigo-700 rounded-lg hover:bg-indigo-200 transition-colors"
          >
            <CalendarDays className="w-4 h-4" />
            <span>Today</span>
          </button>
          <button
            onClick={previousMonth}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={nextMonth}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-2 mb-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-center font-medium text-gray-500 py-2">
            {day}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-2">
        {days.map((day, idx) => {
          const dayEvents = getEventsForDate(day);
          const isSelected = isSameDay(day, selectedDate);
          const isCurrentMonth = isSameMonth(day, currentDate);
          const isToday = isSameDay(day, toPST(new Date()));

          return (
            <button
              key={idx}
              onClick={() => setSelectedDate(day)}
              className={`
                p-2 h-24 text-left rounded-lg transition-colors relative
                ${isSelected ? 'bg-indigo-50 border-2 border-indigo-500' : 'hover:bg-gray-50'}
                ${!isCurrentMonth && 'opacity-50'}
                ${isToday && !isSelected && 'border-2 border-indigo-200'}
              `}
            >
              <span className={`
                text-sm ${isSelected ? 'text-indigo-600 font-medium' : 'text-gray-700'}
                ${isToday && 'font-medium'}
              `}>
                {format(day, 'd')}
              </span>
              {dayEvents.length > 0 && (
                <div className="mt-1 space-y-1">
                  {dayEvents.map((event, eventIdx) => {
                    const colors = categoryColors[event.category];
                    return (
                      <div
                        key={eventIdx}
                        className={`
                          text-xs p-1 rounded truncate
                          ${isFirstDayOfEvent(day, event) ? 'rounded-l-lg' : ''}
                          ${isLastDayOfEvent(day, event) ? 'rounded-r-lg' : ''}
                          ${colors.bg} ${colors.text}
                        `}
                      >
                        {event.title}
                      </div>
                    );
                  })}
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="py-16 bg-gray-50 min-h-screen">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Community Calendar</h1>
          <p className="text-gray-600 mb-6">
            Stay up to date with community events and important dates
          </p>
          <div className="flex justify-center">
            <GoogleCalendarButton isSubscribe />
          </div>
        </div>

        {/* Calendar Legend */}
        <div className="bg-white rounded-lg shadow-md p-4 mb-6">
          <h3 className="text-sm font-medium text-gray-700 mb-3">Event Types</h3>
          <div className="flex flex-wrap gap-4">
            {Object.entries(categoryColors).map(([category, colors]) => (
              <div key={category} className="flex items-center gap-2">
                <div className={`w-4 h-4 rounded ${colors.bg}`}></div>
                <span className="text-sm text-gray-600">{colors.label}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {isMobile ? (
            <>
              <div className="lg:col-span-2">
                <EventDetails />
                <Calendar />
                <FAQSection />
              </div>
            </>
          ) : (
            <>
              <div className="lg:col-span-2">
                <Calendar />
                <FAQSection />
              </div>
              <EventDetails />
            </>
          )}
        </div>
      </div>
    </div>
  );
}