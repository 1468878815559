import React from 'react';
import { Calendar as CalendarIcon } from 'lucide-react';
import { generateGoogleCalendarUrl } from '../utils/googleCalendar';
import { Event } from '../utils/events';

interface GoogleCalendarButtonProps {
  event?: Event;
  isSubscribe?: boolean;
}

// This calendar ID is already populated with our community events
const COMMUNITY_CALENDAR_URL = 'https://calendar.google.com/calendar/u/0?cid=ZWQ1YTYzOTg2OGYzODdjZDBlYmUxMzQ0ODVjOGMyN2ViZWM1ODAwZmExNzg1NjE2YjFlMjI5MDA1ZWExNDNiYUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t';

export default function GoogleCalendarButton({ event, isSubscribe = false }: GoogleCalendarButtonProps) {
  const handleClick = () => {
    const url = isSubscribe 
      ? COMMUNITY_CALENDAR_URL
      : generateGoogleCalendarUrl(event!);
    window.open(url, '_blank');
  };

  return (
    <button
      onClick={handleClick}
      className={`
        flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
        ${isSubscribe 
          ? 'bg-indigo-600 text-white hover:bg-indigo-700' 
          : 'bg-white border border-gray-300 text-gray-700 hover:bg-gray-50'
        }
      `}
    >
      <CalendarIcon className="w-5 h-5" />
      <span>{isSubscribe ? 'Subscribe to Community Calendar' : 'Add to Google Calendar'}</span>
    </button>
  );
}