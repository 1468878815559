import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Users } from 'lucide-react';

interface BoardMemberDetails {
  id: string;
  name: string;
  position: string;
  bio: string;
  joinedYear: string;
  quote: string;
}

export default function BoardMemberBio() {
  const { id } = useParams<{ id: string }>();

  const boardMembers: Record<string, BoardMemberDetails> = {
    "fred-laurino": {
      id: "fred-laurino",
      name: "Fred Laurino",
      position: "President",
      joinedYear: "2014",
      bio: "With over 30 years as a resident and 10 years of dedicated service on the HOA board, I bring extensive experience in community leadership and decision-making. I've continuously expanded my knowledge through multiple courses on HOA management and governance offered by Keystone Property Management Co., ensuring our board meetings and community decisions are handled efficiently and effectively.",
      quote: "My vision remains unchanged since I moved here: to maintain our community as a safe, friendly, and clean environment while ensuring it remains a sought-after place to live and making decisions that increase our property values."
    },
    "patrick-bole": {
      id: "patrick-bole",
      name: "Pat Bole",
      position: "Vice-President",
      joinedYear: "2024",
      bio: "As a Sycamore Creek homeowner since 1983 and a previous board member, I bring decades of community knowledge and experience. My background includes service in the Marines and a career with United Airlines. I'm deeply committed to engaging with our neighbors and maintaining the high standards of our community.",
      quote: "I want our neighborhood to be a most desirable destination for families to make their home, and I'm committed to working with the board in a fiscally responsible way to ensure our property values continue to rise."
    },
    "richard-pineros": {
      id: "richard-pineros",
      name: "Richard Pineros",
      position: "Treasurer",
      joinedYear: "2004",
      bio: "As a retired CPA with experience as a corporate controller and CFO, I've served as the Sycamore Creek HOA Treasurer for the past 20 years. My extensive financial background uniquely positions me to address our community's fiscal challenges, particularly with upcoming projects like street re-paving that require careful asset management and prudent planning.",
      quote: "I am committed to maintaining our community as a safe, pleasant, and family-friendly place while ensuring the fiscal responsibility and aesthetic appeal that Sycamore Creek is known for. I strongly encourage increased homeowner participation in board meetings to strengthen our community governance."
    },
    "andrew-davies": {
      id: "andrew-davies",
      name: "Andrew Davies",
      position: "Secretary",
      joinedYear: "2010",
      bio: "As a resident since 2010 and an attorney specializing in real estate development, I bring valuable legal expertise to our board. With four years of experience representing HOAs and service on multiple HOA and corporate boards, I understand the complexities of community governance and property management.",
      quote: "I am committed to increasing our sense of community while managing our homeowners' funds conscientiously to maintain and enhance our property values."
    },
    "stephanie-sutherland": {
      id: "stephanie-sutherland",
      name: "Stephanie Sutherland",
      position: "Member-at-Large",
      joinedYear: "2022",
      bio: "With 17 years of experience in the financial industry and as a successful small business owner, I bring a unique blend of entrepreneurial expertise and financial acumen to the board. My commitment to community engagement extends beyond professional duties, as I actively participate in organizing neighborhood initiatives and events to enhance our community's livability.",
      quote: "I believe in transparent communication, prudent financial management, and proactive problem solving - all essential qualities for ensuring our neighborhood remains a place we're proud to call home."
    }
  };

  const member = boardMembers[id || ""];

  if (!member) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Board Member Not Found</h2>
          <Link to="/" className="text-indigo-600 hover:text-indigo-700">
            Return Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="flex flex-wrap items-center gap-4 mb-8">
          <Link to="/" className="inline-flex items-center text-gray-600 hover:text-indigo-600">
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Board Members
          </Link>
          
          {/* Quick navigation links */}
          <div className="flex gap-4 ml-auto text-sm">
            <Link to="/calendar" className="text-gray-600 hover:text-indigo-600">Calendar</Link>
            <Link to="/contact" className="text-gray-600 hover:text-indigo-600">Contact</Link>
            <Link to="/resources" className="text-gray-600 hover:text-indigo-600">Resources</Link>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-8">
          <div className="flex items-center gap-6 mb-8">
            <div className="bg-indigo-100 rounded-full p-4">
              <Users className="w-8 h-8 text-indigo-600" />
            </div>
            <div>
              <h1 className="text-3xl font-bold">{member.name}</h1>
              <p className="text-xl text-gray-600">{member.position}</p>
            </div>
          </div>

          <div className="space-y-6">
            <div>
              <h2 className="text-xl font-semibold mb-3">About</h2>
              <p className="text-gray-600 leading-relaxed">{member.bio}</p>
            </div>

            <div className="bg-indigo-50 rounded-lg p-6">
              <blockquote className="text-lg italic text-gray-700">
                "{member.quote}"
              </blockquote>
            </div>

            <div className="border-t pt-6">
              <p className="text-gray-600">
                Board Member since: <span className="font-semibold">{member.joinedYear}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}