import React from 'react';
import { ExternalLink, FileText, Key, Home, Wrench, Shield, HelpCircle, Mail, FileCheck, Building } from 'lucide-react';

interface Resource {
  title: string;
  description: string;
  link: string;
  icon: keyof typeof icons;
  category: string;
  disabled?: boolean;
}

const icons = {
  external: ExternalLink,
  file: FileText,
  key: Key,
  home: Home,
  wrench: Wrench,
  shield: Shield,
  help: HelpCircle,
  mail: Mail,
  check: FileCheck,
  building: Building,
};

export default function ResourcesPage() {
  const resources: Resource[] = [
    {
      title: "Homeowner Portal Login",
      description: "Access your account, make payments, and view important documents.",
      link: "https://kppm.cincwebaxis.com/",
      icon: "key",
      category: "Quick Access"
    },
    {
      title: "Keystone Pacific Property Management",
      description: "Visit our property management company's main website.",
      link: "https://www.kppm.com/",
      icon: "home",
      category: "Quick Access"
    },
    {
      title: "Contact Keystone Pacific",
      description: "Get in touch with our property management team.",
      link: "https://www.kppm.com/contact/",
      icon: "mail",
      category: "Quick Access"
    },
    {
      title: "Architectural Application",
      description: "Submit your architectural modifications for review online.",
      link: "https://keystoneconnection.net",
      icon: "wrench",
      category: "Forms & Applications"
    },
    {
      title: "Digital Architectural Submissions",
      description: "Submit and track your architectural requests through our online portal.",
      link: "https://keystoneconnection.net",
      icon: "check",
      category: "Forms & Applications"
    },
    {
      title: "Community Guidelines",
      description: "Download and review our community rules and regulations (Login required).",
      link: "https://kppm.cincwebaxis.com/cinc/documents",
      icon: "file",
      category: "Important Documents"
    },
    {
      title: "HOA Documents",
      description: "Access CC&Rs, Bylaws, and other governing documents (Login required).",
      link: "https://kppm.cincwebaxis.com/cinc/documents",
      icon: "building",
      category: "Important Documents"
    }
  ];

  const categories = Array.from(new Set(resources.map(r => r.category)));

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Community Resources</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Access important documents, forms, and links for Sycamore Creek residents.
          </p>
        </div>

        <div className="space-y-12">
          {categories.map((category) => (
            <div key={category}>
              <h2 className="text-xl font-semibold mb-6">{category}</h2>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {resources
                  .filter(resource => resource.category === category)
                  .map((resource, index) => {
                    const IconComponent = icons[resource.icon];
                    return (
                      <div
                        key={index}
                        className={`bg-white rounded-lg shadow-md p-6 ${
                          resource.disabled 
                            ? 'opacity-60 cursor-not-allowed' 
                            : 'hover:shadow-lg transition-shadow group cursor-pointer'
                        }`}
                        onClick={() => {
                          if (!resource.disabled && resource.link !== '#') {
                            window.open(resource.link, '_blank');
                          }
                        }}
                      >
                        <div className="flex items-start gap-4">
                          <div className={`bg-indigo-100 rounded-full p-3 ${
                            !resource.disabled && 'group-hover:bg-indigo-200 transition-colors'
                          }`}>
                            <IconComponent className="w-6 h-6 text-indigo-600" />
                          </div>
                          <div>
                            <div className="flex items-center gap-2">
                              <h3 className="font-semibold text-lg">{resource.title}</h3>
                              {resource.link.startsWith('http') && !resource.disabled && (
                                <ExternalLink className="w-4 h-4 text-gray-400 group-hover:text-indigo-600 transition-colors" />
                              )}
                            </div>
                            <p className="text-gray-600 mt-1">{resource.description}</p>
                            {resource.disabled && (
                              <p className="text-sm text-red-500 mt-2">
                                Currently unavailable
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-indigo-50 rounded-lg p-8">
          <div className="flex items-start gap-4">
            <div className="bg-indigo-100 rounded-full p-3">
              <HelpCircle className="w-6 h-6 text-indigo-600" />
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Need Help?</h3>
              <p className="text-gray-700 mb-4">
                Can't find what you're looking for? Our management team is here to help.
              </p>
              <div className="space-y-2">
                <p className="text-gray-600">
                  <strong>Property Manager:</strong> Melanie Clay
                </p>
                <p className="text-gray-600">
                  <strong>Email:</strong>{' '}
                  <a href="mailto:mclay@keystonepacific.com" className="text-indigo-600 hover:text-indigo-700">
                    mclay@keystonepacific.com
                  </a>
                </p>
                <p className="text-gray-600">
                  <strong>Phone:</strong>{' '}
                  <a href="tel:9494037405" className="text-indigo-600 hover:text-indigo-700">
                    (949) 403-7405
                  </a>
                </p>
                <p className="text-gray-600">
                  <strong>Emergency After Hours:</strong>{' '}
                  <a href="tel:9498332600" className="text-indigo-600 hover:text-indigo-700">
                    (949) 833-2600
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}