import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import Hero from './components/Hero';
import Events from './components/Events';
import Announcements from './components/Announcements';
import ContactInfo from './components/ContactInfo';
import BoardMembers from './components/BoardMembers';
import BoardMemberBio from './pages/BoardMemberBio';
import CalendarPage from './pages/CalendarPage';
import ContactPage from './pages/ContactPage';
import ResourcesPage from './pages/ResourcesPage';
import ComingSoonPage from './pages/ComingSoonPage';

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav className="bg-gradient-to-r from-indigo-600 to-indigo-800 text-white px-4 py-4 shadow-md relative">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <Link to="/" className="text-xl font-bold" onClick={closeMenu}>Sycamore Creek Community</Link>
        
        {/* Mobile menu button */}
        <button 
          onClick={toggleMenu}
          className="lg:hidden p-2 hover:bg-indigo-700 rounded-lg transition-colors"
        >
          {isMenuOpen ? (
            <X className="w-6 h-6" />
          ) : (
            <Menu className="w-6 h-6" />
          )}
        </button>

        {/* Desktop menu */}
        <div className="hidden lg:flex gap-8">
          <Link to="/" className="hover:text-gray-200 transition-colors">Home</Link>
          <Link to="/calendar" className="hover:text-gray-200 transition-colors">Calendar</Link>
          <Link to="/contact" className="hover:text-gray-200 transition-colors">Contact</Link>
          <Link to="/resources" className="hover:text-gray-200 transition-colors">Resources</Link>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="lg:hidden absolute top-full left-0 right-0 bg-indigo-800 shadow-lg py-4 z-50">
            <div className="flex flex-col space-y-3 px-4">
              <Link 
                to="/" 
                className="hover:text-gray-200 transition-colors py-2"
                onClick={closeMenu}
              >
                Home
              </Link>
              <Link 
                to="/calendar" 
                className="hover:text-gray-200 transition-colors py-2"
                onClick={closeMenu}
              >
                Calendar
              </Link>
              <Link 
                to="/contact" 
                className="hover:text-gray-200 transition-colors py-2"
                onClick={closeMenu}
              >
                Contact
              </Link>
              <Link 
                to="/resources" 
                className="hover:text-gray-200 transition-colors py-2"
                onClick={closeMenu}
              >
                Resources
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

function HomePage() {
  return (
    <>
      <Hero />
      <Events />
      <Announcements />
      <BoardMembers />
      <ContactInfo />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Navigation />
        
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/calendar" element={<CalendarPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/resources" element={<ResourcesPage />} />
            <Route path="/board-member/:id" element={<BoardMemberBio />} />
            <Route path="/join" element={<ComingSoonPage />} />
          </Routes>
        </main>

        <footer className="bg-gray-900 text-white py-12">
          <div className="max-w-7xl mx-auto px-4">
            <div className="text-center">
              <p className="mb-4">© 2024 Sycamore Creek Community. All rights reserved.</p>
              <div className="flex justify-center gap-6">
                <Link to="/privacy" className="hover:text-gray-300">Privacy Policy</Link>
                <Link to="/terms" className="hover:text-gray-300">Terms of Service</Link>
                <Link to="/contact" className="hover:text-gray-300">Contact Us</Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;