export enum EventType {
  MEETING = 'Meeting',
  COMMUNITY = 'Community',
  MAINTENANCE = 'Maintenance',
  HOLIDAY = 'Holiday'
}

export enum EventCategory {
  HOLIDAY = 'Holiday',
  ROUTINE = 'Routine',
  FEATURED = 'Community',
  MEETING = 'Meeting'
}

export enum RecurringFrequency {
  WEEKLY = 'weekly',
  BIWEEKLY = 'bi-weekly',
  BIMONTHLY = 'bi-monthly'
}