import React from 'react';
import { Calendar, Clock, RefreshCw } from 'lucide-react';
import { EventType, EventCategory, RecurringFrequency } from '../utils/eventTypes';
import GoogleCalendarButton from './GoogleCalendarButton';
import { Event } from '../utils/events';

interface EventCardProps extends Event {
  showAddToCalendar?: boolean;
}

export default function EventCard({ 
  title, 
  startDate,
  endDate,
  time, 
  type, 
  category, 
  description, 
  recurring,
  showAddToCalendar = false,
  ...eventProps
}: EventCardProps) {
  const displayDate = endDate 
    ? `${startDate} - ${endDate}`
    : startDate;

  return (
    <div className="bg-white rounded-lg shadow-md p-6 h-full">
      <div className="text-sm font-medium text-indigo-600 mb-2">{type}</div>
      <h3 className="text-xl font-semibold mb-3">{title}</h3>
      <div className="flex items-center gap-4 text-gray-600 mb-3">
        <div className="flex items-center gap-1">
          <Calendar className="w-4 h-4" />
          <span>{displayDate}</span>
        </div>
        <div className="flex items-center gap-1">
          <Clock className="w-4 h-4" />
          <span>{time}</span>
        </div>
        {recurring && (
          <div className="flex items-center gap-1 text-indigo-600">
            <RefreshCw className="w-4 h-4" />
            <span className="capitalize">{recurring.frequency}</span>
          </div>
        )}
      </div>
      <p className="text-gray-600 mb-4">{description}</p>
      {showAddToCalendar && (
        <div className="mt-4">
          <GoogleCalendarButton event={{ 
            title, 
            startDate, 
            endDate, 
            time, 
            type, 
            category, 
            description, 
            recurring,
            ...eventProps 
          }} />
        </div>
      )}
    </div>
  );
}