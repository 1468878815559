import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Mail, Phone, Users, Send, AlertCircle } from 'lucide-react';

interface ContactFormData {
  recipient: string;
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

interface ContactOption {
  id: string;
  name: string;
  role: string;
  email: string;
  phone?: string;
}

export default function ContactPage() {
  const [selectedContact, setSelectedContact] = useState<ContactOption | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { register, handleSubmit, formState: { errors }, reset } = useForm<ContactFormData>();

  const contactOptions: ContactOption[] = [
    {
      id: 'property-manager',
      name: 'Melanie Clay',
      role: 'Property Manager',
      email: 'mclay@keystonepacific.com',
      phone: '(949) 403-7405'
    },
    {
      id: 'architectural',
      name: 'Architectural Committee',
      role: 'Architectural Review',
      email: 'architectural@keystonepacific.com',
      phone: '(949) 838-3239'
    },
    {
      id: 'maintenance',
      name: 'Maintenance',
      role: 'Common Area Issues',
      email: 'reconnect@keystonepacific.com',
      phone: '(949) 833-2600'
    }
  ];

  const onSubmit = async (data: ContactFormData) => {
    try {
      // In a real implementation, you would send this to your backend
      console.log('Form submitted:', data);
      setIsSubmitted(true);
      reset();
      setTimeout(() => setIsSubmitted(false), 5000);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleRecipientChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = contactOptions.find(option => option.id === e.target.value);
    setSelectedContact(selected || null);
  };

  return (
    <div className="bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Get in touch with our management company and key contacts for any inquiries or assistance.
          </p>
        </div>

        <div className="grid lg:grid-cols-3 gap-8">
          {/* Contact Form */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg shadow-md p-8">
              {isSubmitted && (
                <div className="mb-6 bg-green-50 text-green-700 p-4 rounded-lg flex items-center gap-2">
                  <Send className="w-5 h-5" />
                  <span>Your message has been sent successfully!</span>
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Who would you like to contact?
                  </label>
                  <select
                    {...register('recipient', { required: 'Please select a recipient' })}
                    onChange={handleRecipientChange}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="">Select a recipient</option>
                    {contactOptions.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name} - {option.role}
                      </option>
                    ))}
                  </select>
                  {errors.recipient && (
                    <p className="mt-1 text-sm text-red-600">{errors.recipient.message}</p>
                  )}
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Your Name
                    </label>
                    <input
                      type="text"
                      {...register('name', { required: 'Name is required' })}
                      className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    {errors.name && (
                      <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address
                    </label>
                    <input
                      type="email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address'
                        }
                      })}
                      className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    {errors.email && (
                      <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
                    )}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Phone Number (Optional)
                  </label>
                  <input
                    type="tel"
                    {...register('phone')}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subject
                  </label>
                  <input
                    type="text"
                    {...register('subject', { required: 'Subject is required' })}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                  {errors.subject && (
                    <p className="mt-1 text-sm text-red-600">{errors.subject.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    {...register('message', { required: 'Message is required' })}
                    rows={6}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  ></textarea>
                  {errors.message && (
                    <p className="mt-1 text-sm text-red-600">{errors.message.message}</p>
                  )}
                </div>

                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-indigo-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Send className="w-5 h-5" />
                  Send Message
                </button>
              </form>
            </div>
          </div>

          {/* Contact Information */}
          <div>
            {selectedContact ? (
              <div className="bg-white rounded-lg shadow-md p-8">
                <div className="flex items-center gap-4 mb-6">
                  <div className="bg-indigo-100 rounded-full p-3">
                    <Users className="w-6 h-6 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold">{selectedContact.name}</h3>
                    <p className="text-gray-600">{selectedContact.role}</p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div className="flex items-center gap-3">
                    <Mail className="w-5 h-5 text-indigo-600" />
                    <a
                      href={`mailto:${selectedContact.email}`}
                      className="text-gray-600 hover:text-indigo-600"
                    >
                      {selectedContact.email}
                    </a>
                  </div>

                  {selectedContact.phone && (
                    <div className="flex items-center gap-3">
                      <Phone className="w-5 h-5 text-indigo-600" />
                      <a
                        href={`tel:${selectedContact.phone}`}
                        className="text-gray-600 hover:text-indigo-600"
                      >
                        {selectedContact.phone}
                      </a>
                    </div>
                  )}
                </div>

                <div className="mt-6 p-4 bg-gray-50 rounded-lg flex items-start gap-3">
                  <AlertCircle className="w-5 h-5 text-gray-600 mt-0.5" />
                  <p className="text-sm text-gray-600">
                    For emergencies after hours, please call (949) 833-2600
                  </p>
                </div>
              </div>
            ) : (
              <div className="bg-white rounded-lg shadow-md p-8">
                <p className="text-gray-600 text-center">
                  Select a recipient to view their contact information
                </p>
              </div>
            )}

            <div className="mt-6 bg-indigo-50 rounded-lg p-6">
              <h3 className="text-lg font-semibold mb-4">Quick Tips</h3>
              <ul className="space-y-3 text-gray-700">
                <li className="flex items-start gap-3">
                  <AlertCircle className="w-5 h-5 text-indigo-600 mt-0.5" />
                  <span>For urgent maintenance issues, please call directly</span>
                </li>
                <li className="flex items-start gap-3">
                  <AlertCircle className="w-5 h-5 text-indigo-600 mt-0.5" />
                  <span>Response times may vary based on inquiry type</span>
                </li>
                <li className="flex items-start gap-3">
                  <AlertCircle className="w-5 h-5 text-indigo-600 mt-0.5" />
                  <span>For architectural requests, please use the online form</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}