import { parseISO, addDays } from 'date-fns';
import { formatInPST } from './events';
import type { Event } from './events';

export const generateGoogleCalendarUrl = (event: Event): string => {
  const baseUrl = 'https://calendar.google.com/calendar/render';
  const action = 'TEMPLATE';
  
  const startDate = parseISO(event.startDate);
  // For multi-day events, we need to add one day to the end date to make it inclusive
  const endDate = event.endDate 
    ? addDays(parseISO(event.endDate), 1) // Add one day to make the end date inclusive
    : startDate;
  
  const params = new URLSearchParams({
    action,
    text: event.title,
    details: `${event.description}\n\nThis event is from the Sycamore Creek Community Calendar.`,
    dates: `${formatInPST(startDate, 'yyyyMMdd')}/${formatInPST(endDate, 'yyyyMMdd')}`,
    ctz: 'America/Los_Angeles'
  });

  if (event.location) {
    params.append('location', event.location.address);
  }

  // Add recurrence rule if the event is recurring
  if (event.recurring) {
    const rrule = generateRecurrenceRule(event);
    if (rrule) {
      params.append('recur', rrule);
    }
  }

  return `${baseUrl}?${params.toString()}`;
};

const generateRecurrenceRule = (event: Event): string => {
  if (!event.recurring) return '';

  const { frequency, dayOfWeek, weekOfMonth } = event.recurring;
  let rule = 'RRULE:FREQ=';

  switch (frequency) {
    case 'weekly':
      rule += 'WEEKLY;BYDAY=' + getDayOfWeek(dayOfWeek);
      break;
    case 'bi-weekly':
      if (weekOfMonth) {
        // For bi-weekly events like street sweeping
        rule += `MONTHLY;BYDAY=${weekOfMonth}${getDayOfWeek(dayOfWeek)}`;
      }
      break;
    default:
      return '';
  }

  return rule;
};

const getDayOfWeek = (day: number): string => {
  const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  return days[day] || '';
};