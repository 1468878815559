import React from 'react';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import EventCard from './EventCard';
import { getUpcomingEvents } from '../utils/events';
import GoogleCalendarButton from './GoogleCalendarButton';

const Events = () => {
  const futureEvents = getUpcomingEvents();

  return (
    <section className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">Upcoming Events</h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-6">
            Stay informed about important community events and activities.
          </p>
          <div className="flex justify-center">
            <GoogleCalendarButton isSubscribe />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-2 gap-8">
          {futureEvents.length > 0 ? (
            futureEvents.map((event) => (
              <Link 
                key={event.id}
                to={`/calendar?date=${event.startDate}`}
                className="block h-full hover:transform hover:scale-[1.02] transition-transform"
              >
                <EventCard 
                  {...event}
                  showAddToCalendar={true}
                />
              </Link>
            ))
          ) : (
            <div className="col-span-2 text-center py-8">
              <p className="text-gray-600">No upcoming events scheduled.</p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Events;